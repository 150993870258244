import { useTranslation } from 'react-i18next';

import TableItem from './table-withdrawal-item';

import { StyledEmpty, StyledList } from '../wallet.style';

const TableWithdrawals = ({ data, handleEdit, handleDelete }) => {
  const { t } = useTranslation();

  if (data.length === 0) {
    return (
      <StyledEmpty>
        <p>{t('withdrawalBlock.dontHaveText')}</p>
      </StyledEmpty>
    );
  }

  return (
    <StyledList>
      {data.map((x) => (
        <TableItem
          key={x.id}
          item={x}
          onEdit={() => handleEdit(x)}
          onDelete={() => handleDelete(x)}
        />
      ))}
    </StyledList>
  );
};

export default TableWithdrawals;
