import { useTranslation } from 'react-i18next';

import { ModalEvents } from 'utils/constant';
import { useWallet } from 'contexts/wallet/wallet.context';
import Heading from 'components/common/heading/heading';
import { Button } from 'components/common/button/button';
import PlusIcon from 'components/icons/PlusIcon';
import TableWithdrawals from './table-withdrawal/table-withdrawals';

import { StyledWrapper, StyledHeader } from './wallet.style';

const WalletWithdrawals = () => {
  const { t } = useTranslation();
  const { wallets, emitter } = useWallet();

  const handleAdd = () => emitter.emit(ModalEvents.WITHDRAWAL_MODAL);

  const handleEdit = (data) => emitter.emit(ModalEvents.WITHDRAWAL_MODAL, data);

  const handleDelete = (data) =>
    emitter.emit(ModalEvents.CONFIRM_WITHDRAWAL_MODAL, data);

  return (
    <StyledWrapper>
      <StyledHeader>
        <Heading mb={0} variant="h3">
          {t('withdrawalBlock.title')}
        </Heading>
        <Button
          disabled={wallets.length === 0}
          onClick={wallets.length === 0 ? undefined : handleAdd}
          variant="success"
          icon={<PlusIcon />}
        >
          {t('withdrawalBlock.addButtonText')}
        </Button>
      </StyledHeader>
      <TableWithdrawals
        data={wallets.filter((item) => item.autoWithdraw !== null)}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
      />
    </StyledWrapper>
  );
};

export default WalletWithdrawals;
