import { useTranslation } from 'react-i18next';

import { useWindowSize } from 'utils/hooks/use-window-size';
import useSortableData from 'utils/hooks/use-sortable-data';
import TableHead from './table-head';
import TableItem from './table-wallets-item';

import { StyledEmpty, StyledTable, StyledList } from '../wallet.style';

const TableWallets = ({ columns, data, handleEdit, handleDelete }) => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const { items, requestSort, sortConfig } = useSortableData(data);

  if (items.length === 0) {
    return (
      <StyledEmpty>
        <p>{t('walletsBlock.dontHaveText')}</p>
      </StyledEmpty>
    );
  }

  return (
    <StyledTable>
      {!isMobile && (
        <TableHead
          columns={columns}
          requestSort={requestSort}
          sortConfig={sortConfig}
        />
      )}
      <StyledList>
        {items.map((x) => (
          <TableItem
            key={x.id}
            data={x}
            onEdit={() => handleEdit(x)}
            onDelete={() => handleDelete(x)}
          />
        ))}
      </StyledList>
    </StyledTable>
  );
};

export default TableWallets;
