import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import { useCoins } from 'contexts/coins.context';
import { useUser } from 'contexts/user.context';
import { useNotify } from 'contexts/notification.context';
import { getComponentIcon } from 'utils/constant';
import { normalizeDate, bigIntToFloat } from 'utils/helpers/converters';
import { fadeInOut } from 'utils/motion/fade-in-out';
import Tooltip from 'components/common/tooltip/tooltip';
import EditIcon from 'components/icons/EditIcon';
import DeleteIcon from 'components/icons/DeleteIcon';

import {
  StyledListItem,
  StyledCell,
  StyledCellTitle,
  StyledCellAccent,
  StyledActions,
  StyledActionButton,
  StyledLabel
} from '../wallet.style';

const TableItem = ({ item, onEdit, onDelete }) => {
  const { t } = useTranslation();
  const { coins } = useCoins();
  const { coinId, active, name, createdAt } = item;
  const { userSettings } = useUser();
  const { setMessage } = useNotify();
  const [coin, setCoin] = useState(null);

  useEffect(() => {
    if (coin === null && coins.length > 0) {
      setCoin(coins.find((c) => c.id === coinId));
    }
  }, [coin, coinId, coins]);

  return (
    <motion.li
      layout
      initial="from"
      animate="to"
      exit="from"
      variants={fadeInOut(0.25)}
    >
      <StyledListItem>
        <StyledCell>
          <StyledCellTitle>
            {t('withdrawalBlock.table.dateText')}
          </StyledCellTitle>
          <span>{normalizeDate(createdAt)}</span>
        </StyledCell>
        <StyledCell>
          <StyledCellTitle>
            {t('withdrawalBlock.table.amountText')}
          </StyledCellTitle>
          <StyledCellAccent>
            {item?.autoWithdraw?.type === 'day' && (
              <span>
                {bigIntToFloat(
                  userSettings?.withdraw.find(
                    (item) => item.networkType === 'native'
                  )?.minAmount
                )}
              </span>
            )}
            {item?.autoWithdraw?.type === 'hour' && (
              <span>{item?.autoWithdraw?.fixedAmount}</span>
            )}
            <span>{coin?.tag.toUpperCase()}</span>
            {getComponentIcon(coin?.tag)}
          </StyledCellAccent>
        </StyledCell>
        <StyledCell>
          <StyledCellTitle>
            {t('withdrawalBlock.table.walletText')}
          </StyledCellTitle>
          {name}
        </StyledCell>
        <StyledCell>
          <StyledActions>
            {item?.autoWithdraw?.type === 'hour' && (
              <Tooltip message={t('withdrawalBlock.table.tooltipText')}>
                <StyledActionButton>
                  <StyledLabel>HW</StyledLabel>
                </StyledActionButton>
              </Tooltip>
            )}

            <StyledActionButton
              onClick={
                active
                  ? onEdit
                  : () =>
                      setMessage('notifications.wallets.errorWhenNoActive', {
                        status: 'error'
                      })
              }
              title={t('withdrawalBlock.table.edit')}
            >
              <EditIcon />
            </StyledActionButton>
            <StyledActionButton
              onClick={onDelete}
              title={t('withdrawalBlock.table.delete')}
            >
              <DeleteIcon />
            </StyledActionButton>
          </StyledActions>
        </StyledCell>
      </StyledListItem>
    </motion.li>
  );
};

export default TableItem;
