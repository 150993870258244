import PageTabs from 'components/page-tabs/page-tabs';
import Wallets from 'components/wallets/components/wallets';
import { AUTHORIZED_WALLETS_TABS_ITEMS } from 'site-settings/site-navigation';

import { MainContentArea, Container } from 'assets/styles/pages.style';

export default function WalletsPage() {
  return (
    <MainContentArea>
      <Container>
        <PageTabs items={AUTHORIZED_WALLETS_TABS_ITEMS} />
        <Wallets />
      </Container>
    </MainContentArea>
  );
}
