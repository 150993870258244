import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import CaretDownIcon from 'components/icons/CaretDownIcon';

import {
  StyledTableHead,
  StyledTableRow,
  StyledTableCell
} from '../wallet.style';

export const TableHead = ({ columns, requestSort, sortConfig }) => {
  const { t } = useTranslation();
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return '';
    }
    return sortConfig.key === name ? sortConfig.direction : '';
  };

  return (
    <StyledTableHead>
      <StyledTableRow>
        {columns.map(({ title, dataIndex }) => (
          <StyledTableCell key={dataIndex} className="table-column-has-sorters">
            <div
              className={cx(
                'table-column-sorters',
                getClassNamesFor(dataIndex)
              )}
              role="presentation"
              onClick={() => requestSort(dataIndex)}
            >
              <span className="table-column-title">{t(title)}</span>
              <span className="table-column-sorter">
                <span className="table-caret">
                  <CaretDownIcon />
                </span>
              </span>
            </div>
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </StyledTableHead>
  );
};

export default TableHead;
