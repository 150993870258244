import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const StyledWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 140px;
  }
`;

export const StyledHeader = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 40px;
  }

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    > div {
      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }

    button {
      width: 100%;
    }
  }
`;

export const StyledEmpty = styled.div`
  display: flex;
  justify-content: center;
  padding: 48px 40px;
  background-color: ${themeGet('colors.body.bg')};
  box-shadow: ${themeGet('shadows.section')};
  border-radius: ${themeGet('radii.base')};

  p {
    color: ${themeGet('colors.text.regular')};
    font-size: ${themeGet('fontSizes.base')}px;
    font-weight: ${themeGet('fontWeights.bold')};
  }
`;

export const StyledTable = styled.div``;

const gridValues = () => `
  column-gap: 2%;
  row-gap: 2%;
  grid-template-columns: 7% 17% 26% 15% 16% 9%;

  @media (max-width: 1200px) {
    grid-template-columns: 7% 16% 29% 16% 16% 8%;
  }

  @media (max-width: 991px) {
    grid-gap: 20px;
    grid-template-columns: 1fr;
  }
`;

export const StyledTableRow = styled.div`
  display: grid;

  ${gridValues}
`;

export const StyledTableCell = styled.div`
  padding-bottom: 20px;
  font-size: ${themeGet('fontSizes.sm')}px;
  line-height: 24px;
`;

export const StyledTableHead = styled.div`
  padding-left: 40px;
  padding-right: 45px;

  .table-column-sorters {
    display: flex;
    align-items: center;
    cursor: pointer;

    .table-caret {
      line-height: 0;
      color: ${themeGet('colors.text.regular')};
    }

    &.ascending,
    &.descending {
      .table-caret {
        color: ${themeGet('colors.primary.regular')};
      }
    }

    &.ascending {
      .table-caret {
        transform: rotate(180deg);
      }
    }

    .table-column-sorter {
      display: flex;
      flex-direction: column;
      margin-left: 8px;
      line-height: 1;
    }
  }

  .table-column-title {
    text-transform: uppercase;
    font-weight: ${themeGet('fontWeights.bold')};
    color: ${themeGet('colors.primary.regular')};
  }
`;

export const StyledList = styled.ul`
  list-style: none;

  &:not(:last-child) {
    margin-bottom: 80px;
  }

  > li {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

export const StyledListItem = styled.div`
  padding: 28px 45px 28px 40px;
  background-color: ${themeGet('colors.body.bg')};
  box-shadow: ${themeGet('shadows.section')};
  border-radius: ${themeGet('radii.base')};

  @media (max-width: 991px) {
    padding: 40px 20px;
  }

  display: grid;
  gap: 4%;
  grid-template-columns: 21% 21% 30% 16%;

  @media (max-width: 1200px) {
    grid-template-columns: 19% 20% 24% 21%;
  }

  @media (max-width: 991px) {
    grid-template-columns: 1fr;
  }

  &.mode-table {
    ${gridValues}
  }
`;

export const StyledCell = styled.div`
  font-size: ${themeGet('fontSizes.base')}px;
`;

export const StyledStrong = styled.strong`
  font-weight: ${themeGet('fontWeights.bold')};

  @media (max-width: 991px) {
    font-weight: ${themeGet('fontWeights.regular')};
  }
`;

export const StyledMobileTitle = styled.span`
  display: none;

  @media (max-width: 991px) {
    display: inline-block;
    margin-bottom: 8px;
    margin-right: 30px;
    font-weight: ${themeGet('fontWeights.bold')};
  }
`;

export const StyledCellTitle = styled.span`
  margin-right: 20px;
  font-weight: ${themeGet('fontWeights.bold')};

  @media ${themeGet('mediaQueries.mobileOnly')} {
    display: inline-block;
    min-width: 100px;
  }
`;

export const StyledCellAccent = styled.span`
  display: inline-flex;
  color: ${themeGet('colors.primary.regular')};

  span {
    &:not(:first-child) {
      margin-left: 5px;
    }
  }

  svg {
    margin-left: 8px;
  }
`;

export const StyledFlex = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 991px) {
    display: grid;
    grid-template-columns: 85% 1fr;
  }

  > button {
    margin-left: 7px;
  }
`;

export const StyledOverflow = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledActionButton = styled.button`
  padding: 0;
  background-color: transparent;
  color: ${themeGet('colors.text.regular')};
  transition: ${themeGet('customs.transition')};

  &:hover {
    color: ${themeGet('colors.primary.regular')};
  }
`;

export const StyledActions = styled.div`
  display: flex;
  align-items: center;

  ${StyledActionButton} {
    &:not(:first-child) {
      margin-left: 20px;
    }
  }

  @media (min-width: 992px) {
    justify-content: flex-end;
  }

  @media (max-width: 991px) {
    padding-top: 20px;
  }
`;

export const StyledLabel = styled.span`
  display: inline-block;
  padding: 5px 4px;
  background-color: ${themeGet('colors.primary.regular')};
  color: ${themeGet('colors.text.light')};
  font-size: 11px;
  border-radius: 2px;
`;

export const StyledStatus = styled.span`
  display: flex;
  align-items: center;

  &:before {
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 12px;
    content: '';
    ${(props) => props?.color && `background-color: ${props.color};`}
  }
`;
