import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useMutation } from '@apollo/client';

import { REQUEST_WALLET_ACTIVATION } from 'graphql/wallet.mutation';
import { getStatus } from 'utils';
import { useCoins } from 'contexts/coins.context';
import { normalizeDate, truncateName } from 'utils/helpers/converters';
import { fadeInOut } from 'utils/motion/fade-in-out';
import useCopyToClipboard from 'utils/hooks/use-copy-to-clipboard';

import Tooltip from 'components/common/tooltip/tooltip';
import EmailIcon from 'components/icons/EmailIcon';
import CopyIcon from 'components/icons/CopyIcon';
import EditIcon from 'components/icons/EditIcon';
import DeleteIcon from 'components/icons/DeleteIcon';

import {
  StyledListItem,
  StyledCell,
  StyledFlex,
  StyledActions,
  StyledOverflow,
  StyledActionButton,
  StyledStatus,
  StyledStrong,
  StyledMobileTitle
} from '../wallet.style';

const TableItem = ({ data, onEdit, onDelete }) => {
  const { t } = useTranslation();
  const { coins } = useCoins();
  const { id, createdAt, coinId, name, address, active } = data;

  const [requestWalletActivation, { loading }] = useMutation(
    REQUEST_WALLET_ACTIVATION
  );

  const [statusColor, statusText] = getStatus(active);
  const [copied, copy] = useCopyToClipboard(400);

  const onRequest = async () => {
    const result = await requestWalletActivation({
      variables: {
        walletId: id
      },
      context: { clientName: 'private' }
    });
    if (result?.data?.requestWalletActivation) {
    }
  };

  return (
    <motion.li
      layout
      initial="from"
      animate="to"
      exit="from"
      variants={fadeInOut(0.25)}
    >
      <StyledListItem className="mode-table">
        <StyledCell>
          <StyledMobileTitle>{t('walletsBlock.table.coin')}</StyledMobileTitle>
          <StyledStrong>
            {coins.find((item) => item.id === coinId)?.tag.toUpperCase()}
          </StyledStrong>
        </StyledCell>
        <StyledCell>
          <StyledMobileTitle>{t('walletsBlock.table.name')}</StyledMobileTitle>
          <StyledOverflow>{name}</StyledOverflow>
        </StyledCell>
        <StyledCell>
          <StyledMobileTitle>
            {t('walletsBlock.table.address')}
          </StyledMobileTitle>
          <StyledFlex>
            <StyledOverflow>{truncateName(address)}</StyledOverflow>
            <StyledActionButton onClick={() => copy(address)}>
              {copied ? (
                <Tooltip message={t('walletsBlock.tooltipText')}>
                  <CopyIcon />
                </Tooltip>
              ) : (
                <CopyIcon />
              )}
            </StyledActionButton>
          </StyledFlex>
        </StyledCell>
        <StyledCell>
          <StyledMobileTitle>{t('walletsBlock.table.time')}</StyledMobileTitle>
          {normalizeDate(createdAt)}
        </StyledCell>
        <StyledCell>
          <StyledMobileTitle>
            {t('walletsBlock.table.status')}
          </StyledMobileTitle>
          <StyledStatus color={statusColor}>
            <StyledStrong>{statusText}</StyledStrong>
          </StyledStatus>
        </StyledCell>
        <StyledCell>
          <StyledActions>
            {active !== true && (
              <Tooltip message={t('withdrawalBlock.table.tooltipEmail')}>
                <StyledActionButton onClick={!loading ? onRequest : undefined}>
                  <EmailIcon color="currentColor" />
                </StyledActionButton>
              </Tooltip>
            )}

            <StyledActionButton
              onClick={onEdit}
              title={t('walletsBlock.table.edit')}
            >
              <EditIcon />
            </StyledActionButton>
            <StyledActionButton
              onClick={onDelete}
              title={t('walletsBlock.table.delete')}
            >
              <DeleteIcon />
            </StyledActionButton>
          </StyledActions>
        </StyledCell>
      </StyledListItem>
    </motion.li>
  );
};

export default TableItem;
