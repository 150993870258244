import { useTranslation } from 'react-i18next';

import { ModalEvents } from 'utils/constant';
import { useWallet } from 'contexts/wallet/wallet.context';
import { Button } from 'components/common/button/button';
import Heading from 'components/common/heading/heading';
import PlusIcon from 'components/icons/PlusIcon';
import { columnsWallets } from 'utils/columns-table';
import TableWallets from './table-wallets/table-wallets';

import { StyledWrapper, StyledHeader } from './wallet.style';

const Wallets = () => {
  const { t } = useTranslation();
  const { wallets, emitter } = useWallet();

  const handleAdd = () => emitter.emit(ModalEvents.WALLET_MODAL, null);

  const handleEdit = (data) => emitter.emit(ModalEvents.WALLET_MODAL, data);

  const handleDelete = (data) =>
    emitter.emit(ModalEvents.CONFIRM_WALLET_MODAL, data);

  return (
    <StyledWrapper>
      <StyledHeader>
        <Heading mb={0} variant="h3">
          {t('walletsBlock.title')}
        </Heading>
        <Button onClick={handleAdd} variant="success" icon={<PlusIcon />}>
          {t('walletsBlock.addButtonText')}
        </Button>
      </StyledHeader>
      <TableWallets
        columns={columnsWallets}
        data={wallets}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
      />
    </StyledWrapper>
  );
};

export default Wallets;
