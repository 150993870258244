import { Router } from '@reach/router';
import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { useUser } from 'contexts/user.context';
import { useWallet } from 'contexts/wallet/wallet.context';

import { GET_USER } from 'graphql/user.query';

import SEO from 'components/seo';
import PrivateRoute from 'components/private-route';
import Statistics from 'components/private/statistics';
import Withdrawals from 'components/private/withdrawals';
import Wallets from 'components/private/wallets';
import { useEffect } from 'react';

const WalletsPages = () => {
  const { isAuthenticated, setUser, setBalances } = useUser();
  const { emitter, addWallets } = useWallet();

  const [loadUser, { data, loading, refetch }] = useLazyQuery(GET_USER, {
    context: { clientName: 'private' }
  });

  useEffect(() => {
    const unbind = emitter.on('reload_user', () => {
      refetch();
    });
    return () => unbind();
  }, [emitter, refetch]);

  useEffect(() => {
    if (isAuthenticated) {
      loadUser();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && !loading && data?.user) {
      setUser(data?.user);
      if (data?.user?.balances) {
        setBalances(data?.user?.balances);
      }
      if (data?.user?.wallets) {
        addWallets(data?.user?.wallets);
      }
    }
  }, [data?.user, isAuthenticated, loading]);

  return (
    <Router basepath="/wallets">
      <PrivateRoute path="/statistics" component={Statistics} />
      <PrivateRoute path="/withdrawals" component={Withdrawals} />
      <PrivateRoute path="/" component={Wallets} />
    </Router>
  );
};

export const Head = () => {
  const { t } = useTranslation();
  return <SEO title={t('pageTitles.wallets.wallets.title')} />;
};

export default WalletsPages;
