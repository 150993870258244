import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';

import { GET_USER_HASHRATE } from 'graphql/user.query';

import { useUser } from 'contexts/user.context';
import { useCoins } from 'contexts/coins.context';
import { AUTHORIZED_WALLETS_TABS_ITEMS } from 'site-settings/site-navigation';
import PageTabs from 'components/page-tabs/page-tabs';
import EquipmentIncome from 'components/equipment-income/equipment-income';
import Heading from 'components/common/heading/heading';
import CallToAction from 'components/call-to-action/call-to-action';
import Balance from 'components/balance/balance';
import HashrateStats from 'components/charts/hashrate-stats/hashrate-stats';
import Charts from 'components/charts/charts';
import DailyStatistic from 'components/table-block/daily-statistic/daily-statistic';
import CoinToggler from 'components/common/coin-toggler/coin-toggler';

import {
  Container,
  MainContentArea,
  Row,
  Section,
  Wrapper
} from 'assets/styles/pages.style';

export default function StatisticsPage() {
  const { t } = useTranslation();
  const { balances } = useUser();
  const { loading, activeCoin, setActiveCoin } = useCoins();

  const [
    loadUserHashRate,
    { data: hashrate, loading: hashrateLoading, error: hashrateError }
  ] = useLazyQuery(GET_USER_HASHRATE, {
    context: { clientName: 'private' }
  });

  useEffect(() => {
    if (activeCoin?.id) {
      loadUserHashRate({
        variables: { coinId: activeCoin?.id }
      });
    }
  }, [activeCoin?.id, loadUserHashRate]);

  return (
    <MainContentArea>
      <Container>
        <PageTabs items={AUTHORIZED_WALLETS_TABS_ITEMS} />
        <Row>
          <Balance
            activeCoin={activeCoin}
            balances={balances}
            loader={loading}
            component={
              <CoinToggler state={[activeCoin, setActiveCoin]} width="320px" />
            }
          />
        </Row>
        <Row>
          <Wrapper>
            <Charts
              hashrate={hashrate?.hashRate}
              loading={hashrateLoading}
              error={hashrateError}
            />
            <HashrateStats
              name="userInfo"
              workersName="workers"
              activeCoin={activeCoin}
            />
          </Wrapper>
        </Row>
        <Row px={[0, 0, 4]}>
          <Heading mb={4} variant="h3">
            {t('dailyStatisticBlock.title')}
          </Heading>
          <DailyStatistic activeCoin={activeCoin} />
        </Row>
        <Row>
          <CallToAction />
        </Row>
        <Row>
          <Section p={5}>
            <Heading variant="h3" justifyContent="center">
              {t('equipmentIncomeBlock.titleI18n')}
            </Heading>
            <EquipmentIncome />
          </Section>
        </Row>
      </Container>
    </MainContentArea>
  );
}
