import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import { GET_USER_TRANSACTIONS } from 'graphql/user.query';
import { useWallet } from 'contexts/wallet/wallet.context';

const useLoadTransactions = (activeCoin, pageSize = 7) => {
  const { addTransactions } = useWallet();

  const [hasMoreValues, setHasMoreValues] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [loadingMore, toggleLoading] = useState(false);

  const [getTransactions, { data, loading, refetch, fetchMore }] = useLazyQuery(
    GET_USER_TRANSACTIONS,
    {
      context: { clientName: 'private' },
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    if (activeCoin?.id) {
      getTransactions({
        variables: {
          coinId: activeCoin?.id,
          pageNumber: 1,
          pageSize
        }
      });
    }
  }, [activeCoin?.id, getTransactions]);

  useEffect(() => {
    if (!loading && data?.transactions) {
      addTransactions(data?.transactions);
    }
  }, [data?.transactions]);

  const handleLoadMore = () => {
    toggleLoading(true);
    setPageNumber((p) => p + 1);
    fetchMore({
      variables: {
        coinId: activeCoin?.id,
        pageNumber: pageNumber + 1,
        pageSize
      },
      notifyOnNetworkStatusChange: true,
      updateQuery: (prev, { fetchMoreResult }) => {
        toggleLoading(false);

        if (fetchMoreResult?.transactions.length === 0) {
          setHasMoreValues(false);
          return prev;
        }

        if (!fetchMoreResult) return prev;
        return {
          transactions: [
            ...prev?.transactions,
            ...fetchMoreResult?.transactions
          ]
        };
      }
    });
  };

  return { handleLoadMore, hasMoreValues, refetch, loadingMore };
};

export default useLoadTransactions;
