import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AUTHORIZED_WALLETS_TABS_ITEMS } from 'site-settings/site-navigation';
import { useCoins } from 'contexts/coins.context';
import { useWallet } from 'contexts/wallet/wallet.context';
import PageTabs from 'components/page-tabs/page-tabs';
import Withdrawals from 'components/wallets/components/withdrawals';
import GridTable from 'components/grid-table/grid-table';
import Heading from 'components/common/heading/heading';
import { columnsTransactions } from 'utils/columns-table';
import useLoadTransactions from 'utils/hooks/use-load-transactions';

import { MainContentArea, Container, Row } from 'assets/styles/pages.style';

export default function WithDrawalsPage() {
  const { t } = useTranslation();
  const { activeCoin } = useCoins();
  const { emitter, transactions } = useWallet();

  const { handleLoadMore, refetch, hasMoreValues, loadingMore } =
    useLoadTransactions(activeCoin);

  useEffect(() => {
    const unbind = emitter.on('reload_transactions', () => {
      refetch();
    });
    return () => unbind();
  }, [emitter, refetch]);

  return (
    <MainContentArea>
      <Container>
        <PageTabs items={AUTHORIZED_WALLETS_TABS_ITEMS} />
        <Withdrawals />
        <Row>
          <Heading mb={4} variant="h3">
            {t('transactionsBlock.title')}
          </Heading>
          <GridTable
            handleLoadMore={handleLoadMore}
            columns={columnsTransactions}
            hasMoreValues={hasMoreValues}
            loading={loadingMore}
            data={transactions}
          />
        </Row>
      </Container>
    </MainContentArea>
  );
}
